import React from 'react'

export default props => (
  <svg className="MediumLogo" viewBox="0 0 195 195" {...props}>
    <g fill="none">
      <path d="M0 0h195v195H0z" className="MediumLogo-square" />
      <path
        d="M46.534 65.216c.163-1.608-.45-3.198-1.65-4.28L32.65 46.2V44h37.98l29.356 64.38L125.796 44H162v2.2L151.542 56.23c-.902.687-1.35 1.817-1.162 2.935v73.674c-.187 1.118.26 2.248 1.162 2.935l10.213 10.027v2.2h-51.372v-2.2l10.58-10.273c1.04-1.04 1.04-1.345 1.04-2.934v-59.55l-29.417 74.712H88.61L54.363 73.042v50.074c-.285 2.105.414 4.225 1.896 5.747l13.76 16.69v2.202H31v-2.2l13.76-16.692c1.472-1.525 2.13-3.66 1.774-5.747v-57.9z"
        className="MediumLogo-m"
      />
    </g>
  </svg>
)
